import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Button, { ButtonProps } from '@mui/material/Button';
// import { Link } from 'react-router-dom';

const ApiUrl = process.env.REACT_APP_API_URL;
const stripePaymentLink = process.env.REACT_APP_STRIPE_PAYMENT_LINK;

async function checkFobCode(fobCode: string) {
  try {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'GET',
      // headers: { 'Content-Type': 'application/json' },
      // body: ""
    };
    const response = await fetch(
      ApiUrl + fobCode,
      requestOptions
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error in checking Fob', error);
  }
}

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '360px',
  marginTop: '20px',
  backgroundColor: '#D00000',
  color: 'white',
  background: 'linear-gradient(180deg, #FF4747 0%, #D00000 100%)',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(180deg, #f75151 0%, #c30606 100%)',
  },
}));

const Home = () => {
  const [allChecksSuccessFull, setAllChecksSuccessFull] = useState(false);
  const [fobCode, setFobCode] = useState('');
  const [fobCodeHelperText, setFobCodeHelperText] = useState(
    'Example Fob Codes: A1B2-C3D4-E5F6 or 12345678'
  );
  const [fobError, setFobError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault(); // 👈️ prevent page refresh

    if (!fobCode.trim()) {
      // Empty
      setFobError(true);
      setFobCodeHelperText('Please enter valid fob code');

      return;
    }

    // 👇️ access input values here
    const cleanedFobCode = fobCode.replaceAll('-', '');
    const caseCorrectedFobCode = cleanedFobCode.toUpperCase();

    const checkFobCodeResponse = await checkFobCode(caseCorrectedFobCode);
    if (checkFobCodeResponse.error) {
      // Error
      setFobError(true);
      setFobCodeHelperText(checkFobCodeResponse.error.message);
    } else if (checkFobCodeResponse.status === 'unassigned') {
      // Card not assigned to a school
      setFobError(true);
      setFobCodeHelperText('Please contact support, then try again.');
    } else {
      setFobError(false);

      setButtonDisabled(true);

      // setAllChecksSuccessFull(true);

      // 👇️ clear all input values in the form
      setFobCode('');

      // setTimeout(() => {
        // Go to payment page
        window.location.href = stripePaymentLink + '?client_reference_id=' + checkFobCodeResponse.id;
      // }, 2000);
    }
  };

  return (
    <div className="Home">
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          color: 'text.primary',
          borderRadius: 1,
          p: 3,
        }}
      >
        <Collapse in={!allChecksSuccessFull}>
          <Stack spacing={2}>
            <FormControl sx={{ width: '40ch', marginTop: '40px' }}>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  error={fobError}
                  id="outlined-basic"
                  label="Please enter fob code"
                  helperText={fobCodeHelperText}
                  variant="outlined"
                  color="error"
                  margin="dense"
                  inputProps={{
                    inputMode: 'text',
                    pattern: '[0-9A-Fa-f_-]{14}|[0-9A-Fa-f]{12}|[0-9]{8}',
                  }}
                  onChange={(event) => setFobCode(event.target.value)}
                  value={fobCode}
                />
                {/* <OutlinedInput placeholder="Please enter text" /> */}
                {/* <CustomFormHelperText /> */}
                {/* <Button
                  variant="contained"
                  color="error"
                  size="large"
                  type="submit"
                >
                  TopUp
                </Button> */}
                <ColorButton variant="contained" disabled={buttonDisabled} size="large" type="submit">Top up now</ColorButton>
              </form>
            </FormControl>
          </Stack>
        </Collapse>
        {/* <nav>
          <Link to="/topUp">TopUp</Link>
        </nav> */}
      </Box>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          borderRadius: 1,
          p: 3,
        }}
      >
        <Collapse in={allChecksSuccessFull}>
          <Alert severity="success">
            Successfully verified Fob, redirecting to payment page...
          </Alert>
        </Collapse>
      </Box>
    </div>
  );
};

export default Home;
