import React, { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import Router from './Router';
import './App.css';

const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

function App() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Grid container sx={{ color: 'text.primary' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.default',
              color: 'text.primary',
              borderRadius: 1,
              p: 3,
            }}
          >
            {/* <Grid item xs={11}></Grid>
            <Grid item xs={1}>
              <Tooltip
                title={`Turn ${
                  theme.palette.mode === 'dark' ? 'on' : 'off'
                } the light`}
              >
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={colorMode.toggleColorMode}
                  color="inherit"
                >
                  {theme.palette.mode === 'dark' ? (
                    <LightModeOutlinedIcon />
                  ) : (
                    <DarkModeOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Box>
          <Grid item xs={12}>
              {/* <Typography variant="h4" gutterBottom>
                Welcome to LunchClub Payment
              </Typography> */}
              <img className='logo' src="/images/logos/Lunch-Club-Logo-White.png" alt='Logo'/>
            </Grid>
        </Grid>
        <Router></Router>
      </div>
    </ThemeProvider>
  );
}

function ToggleColorMode() {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const defaultThemeMode = prefersDarkMode ? 'dark' : 'light';

  // const [mode, setMode] = React.useState<'light' | 'dark'>(defaultThemeMode);
  const [mode, setMode] = React.useState<'light' | 'dark'>('light');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // mode,
          background: {
            default: `linear-gradient(180deg, #FF4747 0%, #D00000 100%)`
          }
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                backgroundColor: '#FF4747',
                backgroundImage: `linear-gradient(180deg, #FF4747 0%, #D00000 100%)`,
              },
            },
          },
        }
      }),
    [mode]
  );

  // useEffect(() => {
  //   // Change theme mode based on system preference
  //   setMode(prefersDarkMode ? 'dark' : 'light');
  // }, [prefersDarkMode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default ToggleColorMode;
