import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import TopUp from './pages/TopUp/TopUp';

const Router = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route path='/' element={<Home />}></Route>
      <Route path='/TopUp' element={<TopUp />}></Route>
    </Routes>
  );
}

export default Router;